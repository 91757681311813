<template>
  <!-- 会员充值 -->
  <div class="container">
    <el-tabs
      v-model="activeName"
      type="card"
      class="box1"
      @tab-click="handleClick"
    >
      <!-- 充值申请 -->
      <el-tab-pane label="充值申请" name="first">
        <!-- 按钮列表 -->
        <div class="btnList1" ref="btnList">
          <el-button
            v-if="hasPermiss('ysgl_huiYuanChongZhi:recharge')"
            class="itemBtn btnColor"
            @click="addBtn"
            >新建充值申请</el-button
          >
          <!-- <el-button class="itemBtn btnColor">取消申请</el-button> -->
          <el-button
            v-if="hasPermiss('ysgl_huiYuanChongZhi:update')"
            class="itemBtn btnColor"
            @click="editBtn"
            >修改</el-button
          >
          <el-button
            v-if="hasPermiss('ysgl_huiYuanChongZhi:handel')"
            class="itemBtn btnColor"
            @click="handleMemberRecharge"
            >充值处理</el-button
          >
          <el-button class="itemBtn btnColor" @click="myModel"
            >列表设置</el-button
          >
          <el-dropdown @command="handleCommand_dc">
            <el-button class="dropdownBtn">
              导出<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="dc">导出</el-dropdown-item>
              <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
              <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 查询条件 -->
        <el-form :model="queryData">
          <div
            ref="queryCriteria"
            class="HeadInputbox"
            :class="{ HeadInputbox1: judge, active: isActive }"
          >
            <div class="item_right">
              <div class="singleinp">
                <div class="singleinpleft">充值编号:</div>
                <div class="singleinpright">
                  <el-input
                    class="input"
                    v-model="queryData.transferNumber"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">会员名称:</div>
                <div class="singleinpright">
                  <el-input
                    class="input"
                    v-model="queryData.nickname"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">会员号:</div>
                <div class="singleinpright">
                  <el-input
                    class="input"
                    v-model="queryData.memberId"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </div>
              </div>

              <div class="singleinp">
                <div class="singleinpleft">充值类型:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryData.transferStatus"
                    filterable
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in transferStatusList"
                      :key="item.statusVal"
                      :label="item.showName"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">仓库:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryData.storageId"
                    filterable
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in cangkuList"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="singleinp">
                <div class="singleinpleft">申请起始日期:</div>
                <div class="singleinpright">
                  <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="queryData.createStartTime"
                    type="datetime"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </div>

              <div class="singleinp">
                <div class="singleinpleft">申请结束日期:</div>
                <div class="singleinpright">
                  <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="queryData.createEndTime"
                    type="datetime"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div
              class="item_left"
              v-if="hasPermiss('ysgl_huiYuanChongZhi:query')"
            >
              <div class="singleinpbtn">
                <el-button
                  class="seniorbut"
                  @click="queryBtn_ok"
                  icon="el-icon-search"
                  >查询</el-button
                >
                <el-tooltip content="查询项恢复初始状态" placement="top">
                  <div class="condition" @click="queryDataClera(1)">清除</div>
                </el-tooltip>
                <el-tooltip content="查询更多条件" placement="top">
                  <div class="condition" @click="more">
                    {{ MoreConditions }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="EmptyBox"></div>
        </el-form>
        <!-- 表格 -->
        <!-- height  判断当表格超出某个高度的时候,固定高度,否则自动高度 具体的需要自己计算 -->
        <div ref="tableWrapper">
          <div>
            <el-table
              :header-cell-style="{ background: '#f0f0f0' }"
              :height="tableHeigth"
              ref="mytable"
              @row-click="rowclick"
              :data="tableData"
              :row-class-name="tableRowClassName"
              highlight-current-row
              show-summary
              :summary-method="getSummaries"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                fixed="left"
                label="序号"
                type="index"
                width="60"
              >
              </el-table-column>
              <el-table-column fixed="left" type="selection" width="60">
              </el-table-column>

              <template v-for="(item, index) in myTableHeard">
                <el-table-column
                  class="tablebox"
                  :key="index"
                  :label="item.name"
                  :prop="item.field"
                  :min-width="item.width"
                  sortable
                  :data-isTotal="item.isTotal"
                  v-if="item && item.isShow"
                >
                </el-table-column>
              </template>
            </el-table>
          </div>
          <paging
            :pageNum="pageNum"
            :total="total"
            :sizeList="sizeList"
            :size="size"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          ></paging>
        </div>
        <sorttable
          :show_sortTableHeard="show_sortTableHeard"
          :allCheck="allCheck"
          :sortName="sortName"
          :tabelHeadeTitle="tabelHeadeTitle"
          :originalTabelHeadeTitle="originalTabelHeadeTitle"
          :selectLength="selectLength"
          @cancelSortHeard="cancelSortHeard"
          @confirmOk="confirmOk"
          @checktab="checktab"
          @checkAll="checkAll"
          @closeDias="closeDias"
        ></sorttable>

        <!-- 分页组件 -->
      </el-tab-pane>
      <!-- 充值记录 -->
      <el-tab-pane label="充值记录" name="second">
        <!-- 按钮列表 -->
        <div class="btnList1" ref="btnList1">
          <el-button class="itemBtn btnColor" @click="myModel"
            >列表设置</el-button
          >
          <el-dropdown @command="handleCommand_dc">
            <el-button class="dropdownBtn">
              导出<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="dc">导出</el-dropdown-item>
              <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
              <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <el-form :model="queryData">
          <div
            ref="queryCriteria1"
            class="HeadInputbox"
            :class="{ HeadInputbox1: judge, active: isActive }"
          >
            <div class="item_right">
              <div class="singleinp">
                <div class="singleinpleft">充值编号:</div>
                <div class="singleinpright">
                  <el-input
                    class="input"
                    v-model="queryData.transferNumber"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">会员名称:</div>
                <div class="singleinpright">
                  <el-input
                    class="input"
                    v-model="queryData.nickname"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">会员号:</div>
                <div class="singleinpright">
                  <el-input
                    class="input"
                    v-model="queryData.memberId"
                    placeholder="请输入内容"
                    clearable
                  ></el-input>
                </div>
              </div>

              <div class="singleinp">
                <div class="singleinpleft">充值类型:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryData.transferStatus"
                    filterable
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in transferStatusList"
                      :key="item.statusVal"
                      :label="item.showName"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">仓库:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryData.storageId"
                    filterable
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in cangkuList"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>

              <div class="singleinp">
                <div class="singleinpleft">申请起始日期:</div>
                <div class="singleinpright">
                  <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="queryData.createStartTime"
                    type="datetime"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </div>

              <div class="singleinp">
                <div class="singleinpleft">申请结束日期:</div>
                <div class="singleinpright">
                  <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="queryData.createEndTime"
                    type="datetime"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div
              class="item_left"
              v-if="hasPermiss('ysgl_huiYuanChongZhi:query')"
            >
              <div class="singleinpbtn">
                <el-button
                  class="seniorbut"
                  @click="queryBtn_ok"
                  icon="el-icon-search"
                  >查询</el-button
                >
                <el-tooltip content="查询项恢复初始状态" placement="top">
                  <div class="condition" @click="queryDataClera(2)">清除</div>
                </el-tooltip>
                <el-tooltip content="查询更多条件" placement="top">
                  <div class="condition" @click="more">
                    {{ MoreConditions }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="EmptyBox"></div>
        </el-form>

        <!-- 表格 -->
        <!-- height  判断当表格超出某个高度的时候,固定高度,否则自动高度 具体的需要自己计算 -->
        <div ref="tableWrapper">
          <div>
            <el-table
              :header-cell-style="{ background: '#f0f0f0' }"
              :height="tableHeigth"
              :data="tableData"
              :row-class-name="tableRowClassName"
              border
              show-summary
              :summary-method="getSummaries"
            >
              <el-table-column
                fixed="left"
                label="序号"
                type="index"
                width="60"
              >
              </el-table-column>
              <!-- <el-table-column fixed="left" type="selection" width="60"> -->
              <!-- </el-table-column> -->
              <template v-for="(item, index) in myTableHeard1">
                <el-table-column
                  :key="index"
                  :label="item.name"
                  sortable
                  :prop="item.field"
                  :min-width="item.width"
                  :data-isTotal="item.isTotal"
                  v-if="item && item.isShow"
                >
                </el-table-column>
              </template>
            </el-table>
          </div>
          <paging
            :pageNum="pageNum"
            :total="total"
            :sizeList="sizeList"
            :size="size"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          ></paging>
        </div>

        <!-- 分页组件 -->
      </el-tab-pane>
    </el-tabs>
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 充值申请 -->
    <el-dialog
      :title="diaVistitle"
      :modal-append-to-body="false"
      :visible.sync="diaVisible_add"
      width="40%"
      center
      @close="closeDiaAddEdit"
    >
      <div class="newForm">
        <el-form
          label-width="100px"
          ref="formCate"
          :model="addAndEditData"
          class="formDialog"
        >
          <div class="queryItem1">
            <el-form-item label="会员号:">
              <el-select
                :disabled="diaVistitle == '修改充值申请'"
                v-model="addAndEditData.memberId"
                filterable
                placeholder="请选择"
                @visible-change="getMemberLists"
                remote
                :remote-method="getMList"
              >
                <el-option
                  v-for="(item, index) in huiyuanList"
                  :label="item.memberId"
                  :value="item.memberId"
                  :key="index"
                >
                  {{ item.memberId }} : ({{ item.nickname }})
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="充值流水号:">
              <el-input v-model="addAndEditData.orderNumber"></el-input>
            </el-form-item>
          </div>

          <div class="queryItem1">
            <el-form-item label="币种:">
              <el-select
                v-model="addAndEditData.currency"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in currencyList"
                  :key="item.cnName"
                  :label="item.cnName"
                  :value="item.cnName"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="交易金额:">
              <el-input
                type="number"
                v-model.trim="addAndEditData.transferAmount"
              ></el-input>
            </el-form-item>
          </div>
          <!-- <div class="queryItem1">
            <el-form-item label="转账银行:">
              <el-input v-model.trim="addAndEditData.transferBank"></el-input>
            </el-form-item>
          </div> -->
          <div class="queryItem1">
            <el-form-item label="收款账户:">
              <el-select
                v-model="addAndEditData.financeAssetaccountId"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in financeAssetaccountList"
                  :key="item.accountId"
                  :label="item.accountName + ' (' + item.account + ')'"
                  :value="item.accountId"
                  >{{ item.accountName }} ({{ item.account }})</el-option
                >
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="付款账号:">
              <el-select
                v-model="addAndEditData.bankUserAccountId"
                placeholder="请选择"
                clearable
                filterable
                remote
                :remote-method="
                  (val) =>
                    getFinanceUserAccountList(true, addAndEditData.memberId)
                "
                @visible-change="
                  getFinanceUserAccountList($event, addAndEditData.memberId)
                "
              >
                <el-option
                  v-for="(item, idx) in financeUserAccountList"
                  :key="idx"
                  :label="item.bankAccount"
                  :value="item.userBankId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="转账时间:">
              <el-date-picker
                v-model="addAndEditData.transferTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                style="width: 100%"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </div>

          <div class="queryItem1" style="width: 100%">
            <el-form-item label="备注:" style="width: 100%">
              <el-input
                type="textarea"
                rows="3"
                placeholder="请输入"
                v-model="addAndEditData.rechargComment"
              ></el-input>
            </el-form-item>
          </div>

          <div class="queryItem1" style="border: none; width: 100%">
            <el-form-item label="上传图片:"></el-form-item>
          </div>

          <div class="" style="border: none">
            <el-upload
              :action="uploadUrl"
              :http-request="uploadSectionImg"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :file-list="fileListImg"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_addEdit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 充值处理 -->
    <el-dialog
      title="充值处理"
      :visible.sync="diaVisible_chuLi"
      width="40%"
      center
    >
      <div class="newForm">
        <el-form
          label-width="100px"
          ref="formCate"
          :model="infosForm"
          :rules="rules"
          class="formDialog"
        >
          <div class="queryItem1">
            <el-form-item label="会员昵称:">
              <el-input disabled v-model="infosForm.nickname"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="会员号:">
              <el-input disabled v-model="infosForm.memberId"></el-input>
            </el-form-item>
          </div>

          <div class="queryItem1">
            <el-form-item label="充值编号:">
              <el-input
                disabled
                v-model="infosForm.billTransferNumber"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="流水号:">
              <el-input disabled v-model="infosForm.orderNumber"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="充值币种:">
              <el-input disabled v-model="infosForm.currency"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="充值币种金额:">
              <el-input disabled v-model="infosForm.transferAmount"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="充值币种汇率:">
              <el-input disabled v-model="infosForm.exchangeRate"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="实充人民币:">
              <el-input disabled v-model="infosForm.dealAmount"></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="充值类型:">
              <el-select
                v-model="infosForm.transferStatus"
                filterable
                disabled
                placeholder="请选择"
              >
                <el-option
                  v-for="item in transferStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="审批状态:" prop="approvalStatus">
              <el-select
                v-model="infosForm.approvalStatus"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in approvalStatusList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                  :disabled="item.statusVal == 'shen_pi_zhong:approval_status'"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="收款账户:">
              <el-select
                v-model="infosForm.financeAssetaccountId"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in financeAssetaccountList"
                  :key="item.accountId"
                  :label="item.accountName + ' (' + item.account + ')'"
                  :value="item.accountId"
                  >{{ item.accountName }} ({{ item.account }})</el-option
                >
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="付款账号:">
              <el-select
                v-model="infosForm.bankUserAccountId"
                placeholder=""
                clearable
                disabled
              >
                <el-option
                  v-for="(item, idx) in financeUserAccountList"
                  :key="idx"
                  :label="item.bankAccount"
                  :value="item.userBankId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div
            class="queryItem1"
            v-if="infosForm.approvalStatus == 'ju_jue:approval_status'"
          >
            <el-form-item label="*拒绝原因:" prop="rejectionReason">
              <el-input
                type="textarea"
                rows="3"
                v-model="infosForm.rejectionReason"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1" style="width: 100%">
            <el-form-item label="备注:">
              <el-input
                type="textarea"
                rows="3"
                v-model="infosForm.rechargComment"
                disabled
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1" style="width: 100%">
            <el-form-item label="处理备注:">
              <el-input
                type="textarea"
                rows="3"
                placeholder="请输入"
                v-model="infosForm.comment"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1" style="width: 100%">
            <el-form-item label="图片:">
              <el-image
                @click="showBigImgclick(item.url)"
                style="width: 200px; height: 200px"
                v-for="(item, index) in infosForm.pics"
                :key="index"
                :src="item.url"
              ></el-image>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmBtn_chuLi">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 充值处理 -->
    <el-dialog :visible.sync="showBigImg" width="50%" center @close="closeImg">
      <div class="newForm">
        <el-image
          style="width: 400px; height: 400px"
          fit="contain"
          :src="bigImgs"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showBigImg = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";
import sorttable from "@/components/sortTemplate.vue";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
  },
  data() {
    return {
      QueryNumber: false, //查询会员号
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      dialogImageUrl: "",
      dialogVisible: false,
      CancelSignIn: false,
      viewPicture: false,
      showBigImg: false,
      activeName: "first",
      form: {
        name: "",
      },
      WarehouseList: [], //仓库数组
      packageStatusList: [], ////包裹状态
      queryData: {
        createEndTime: "", //申请结束时间
        createStartTime: "", //申请开始时间
        memberId: "", //会员号
        nickname: "", //会员名称
        storageId: "", //仓库id
        transferStatus: "", //充值类型
        transferNumber: "", //充值编号
        approvalStatus: "shen_pi_zhong:approval_status", //审批状态
      },

      // ======公用组件==========
      pageNum: 1, //页码
      total: 0,
      size: 50, //一页几条
      pageNum2: 1, //页码
      total2: 0,
      size2: 50, //一页几条
      sizeList: [10, 20, 50, 100, 200],
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_sortTableHeard1: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      myTableHeard1: [], //我的表头
      selectLength: 0, //显示的长度
      selectLength1: 0, //显示的长度
      allCheck: false, //全选
      allCheck1: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "充值编号",
          field: "billTransferNumber",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "线下充值单号",
          field: "orderNumber",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "资金账户",
          field: "accountName",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickname",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "付款账号",
          field: "bankUserAccount",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "付款账号名称",
          field: "bankUserAccountName",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "充值币种",
          field: "currency",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "充值币种汇率",
          field: "exchangeRate",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "充值币种金额",
          field: "transferAmount",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "实充人民币",
          field: "dealAmount",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "充值类型",
          field: "transferTypeShow",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        // {
        //   name: "充值类型",
        //   field: "transferStatusShow",
        //   width: "120",
        //   sort: 3,
        //   isShow: true,
        //   isTotal: false,
        //   isSort: true,
        // },
        {
          name: "审核状态",
          field: "approvalStatusShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核人",
          field: "handlerUserName",
          width: "160",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "160",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "transferorUserName",
          width: "160",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "仓库名称",
          field: "storageName",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "备注",
          field: "rechargComment",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "处理备注",
          field: "comment",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tabelHeadeTitle1: [
        {
          name: "充值编号",
          field: "billTransferNumber",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickname",
          width: "120",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "付款账号",
          field: "bankUserAccount",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "付款账号名称",
          field: "bankUserAccountName",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "充值币种",
          field: "currency",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "充值币种汇率",
          field: "exchangeRate",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "充值币种金额",
          field: "transferAmount",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "实充人民币",
          field: "dealAmount",
          width: "100",
          sort: 7,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "充值类型",
          field: "transferTypeShow",
          width: "100",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核状态",
          field: "approvalStatusShow",
          width: "100",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "审核人",
          field: "handlerUserName",
          width: "160",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "120",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "transferorUserName",
          width: "160",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "仓库名称",
          field: "storageName",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "备注",
          field: "rechargComment",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "处理备注",
          field: "comment",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      tableData1: [],
      totalArr: [], //需要合计的字段
      totalArr1: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      fileListImg: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      sortName1: "模板名", //排序名
      pageGroupName: "Recharge", //页面标识
      cangkuList: [],
      currencyList: [
        {
          currencyId: "",
          cnName: "人民币",
          enName: "人民币",
        },
      ], //币种
      financeAssetaccountList: [],
      financeUserAccountList: [],
      transferStatusList: [], //充值类型
      approvalStatusList: [], //审批状态

      //会员下拉列表
      huiyuanList: [],

      currentSelectList: [], //当前选中的行
      currentSelectList2: [], //当前选中的行
      diaVisible_chuLi: false,
      diaVisible_add: false,
      infosForm: {
        transferStatus: "",
        billTransferNumber: "",
        bankUserAccount: "",
        orderNumber: "",
        // comment: "",
        rechargComment: "",
        approvalStatus: "",
        billTransferId: "",
        financeAssetaccountId: "",
        bankUserAccountId: "",
        nickname: "",
        pics: [],
      },
      bigImgs: "",
      addAndEditData: {
        currency: "", //货币名称
        dealAmount: "", //交易金额
        transferAmount: "", //交易金额
        orderNumber: "", //订单号/流水号
        pics: [],
        transferBank: "", //转账银行
        transferTime: "", //转账时间
        bankUserAccountId: "", //付款账号id
        memberId: "", //会员号
        comment: "", //
      },
      ImgNames: [], //
      diaVistitle: "新建申请",

      rules: {
        approvalStatus: [
          { required: true, message: "请输入", trigger: ["blur", "change"] },
        ],
      },
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.getStorageList(); //仓库列表
    this.capitalAccountList(); //资金账户列表
    this.getCurrencyDownList(); //币种列表
    this.getStatusValList("bill_transfer_v1.transfer_status"); //充值类型
    this.getStatusValList("bill_transfer_v1.approval_status"); //	充值审批状态
  },
  //页面销毁
  beforeDestroy() {},
  mounted() {
    this.getData();
  },

  watch: {
    activeName(n, o) {
      if (n == "first") {
        this.queryData.approvalStatus = "shen_pi_zhong:approval_status";
        this.pageGroupName = "baoruorenglin";
        this.pageNum = 1;
        this.getGroupFindFieldSort();
        this.getData();
      } else {
        this.queryData.approvalStatus = "";
        this.pageGroupName = "baoruorenglin1";
        this.pageNum = 1;
        this.SignInRecord();
        this.getData();
      }
    },
  },
  methods: {
    // 点击选中当前行
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },

    closeImg(img) {
      this.bigImgs = "";
    },
    showBigImgclick(img) {
      this.showBigImg = true;
      this.bigImgs = img;
    },
    getCurrencyDownList() {
      Api.getCurrencyDownList().then((res) => {
        this.currencyList = res.data.result || [];
      });
    },
    //新建申请
    addBtn() {
      this.diaVisible_add = true;
      this.diaVistitle = "新建充值申请";
    },

    getMemberLists(e) {
      if (e) {
        console.log("调接口");
        this.getMemberDownList();
      }
    },
    getMList(e) {
      this.getMemberDownList(e);
    },
    //修改申请
    editBtn() {
      if (this.currentSelectList.length > 1) {
        this.$message.warning("一次只能修改一条记录哦~");
        return;
      }
      if (this.currentSelectList.length < 1) {
        this.$message.warning("请选择一条您要修改的申请~");
        return;
      }
      this.diaVisible_add = true;
      this.diaVistitle = "修改充值申请";
      let datas = this.currentSelectList[0];
      Api.memberRechargeDetail({ billTransferId: datas.billTransferId }).then(
        (res) => {
          this.addAndEditData = res.data.result;
          // this.addAndEditData.dealAmount =
          //   this.addAndEditData.transferAmount || 0;
          let urls = res.data.result.pics || [];
          console.log(urls);
          let fileArr = [];
          let imgArr = [];

          urls.forEach((item, index) => {
            let fileObj = {
              name: item.fileName,
              url: item.url,
            };
            fileArr.push(fileObj);
            let objs = {
              imgName: item.fileName,
              oldName: "",
            };
            imgArr.push(objs);
          });
          this.fileListImg = JSON.parse(JSON.stringify(fileArr));
          this.ImgNames = JSON.parse(JSON.stringify(imgArr));
          if (res.data.result.bankUserAccountId) {
            this.financeUserAccountList = [
              {
                userBankId: res.data.result.bankUserAccountId || "",
                accountName: res.data.result.bankUserAccountName || "",
                bankAccount: res.data.result.bankUserAccount || "",
              },
            ];
          }
        }
      );
    },

    //关闭弹窗
    closeDiaAddEdit() {
      this.addAndEditData = {
        currency: "", //货币名称
        dealAmount: "", //交易金额
        transferAmount: "", //交易金额
        orderNumber: "", //订单号/流水号
        pics: [],
        transferBank: "", //转账银行
        transferTime: "", //转账时间
        memberId: "", //会员id
        comment: "", //
      };
      this.ImgNames = [];
      this.fileListImg = [];
    },
    // 获取状态展示值
    getStatusValList(status) {
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 充值类型
        if (status == "bill_transfer_v1.transfer_status") {
          let dataArr = res.data.result || [];
          this.transferStatusList = dataArr;
        }
        // 审批状态
        if (status == "bill_transfer_v1.approval_status") {
          let dataArr = res.data.result || [];
          this.approvalStatusList = dataArr;
        }
      });
    },
    //获取付款账号下拉列表
    getFinanceUserAccountList(e, memberId) {
      if (!e) {
        return false;
      }
      Api.memberBankList({
        memberId: memberId,
      }).then((res) => {
        let lists = res.data.result.data || [];
        this.financeUserAccountList = lists;
      });
    },
    //获取仓库下拉列表
    capitalAccountList() {
      Api.capitalAccountList().then((res) => {
        //////console.log(res.data.result)
        let lists = res.data.result || [];
        this.financeAssetaccountList = lists;
      });
    },
    //获取仓库下拉列表
    getStorageList() {
      Api.getStorageList().then((res) => {
        //////console.log(res.data.result)
        let cangkuList = res.data.result || [];
        this.cangkuList = cangkuList;
      });
    },
    //获取会员下拉列表
    getMemberDownList(val) {
      Api.getMemberDownList({
        memberId: val || "",
      }).then((res) => {
        console.log(res.data.result.data);
        this.huiyuanList = res.data.result.data || [];
      });
    },
    //删除
    deletebut() {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message.success(res.data.message || "操作成功");
      });
    },

    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },

    handleCommandTabs(m) {
      if (m == "a") {
        this.myModel();
      } else if (m == "b") {
        this.exportBtn();
      } else if (m == "c") {
        this.exportTemplateDownload();
      } else if (m == "d") {
        this.upLoadBtn();
      }
    },

    // 充值处理
    handleMemberRecharge() {
      if (this.currentSelectList.length > 1) {
        this.$message.warning("一次只能处理一条记录哦~");
        return;
      }
      if (this.currentSelectList.length < 1) {
        this.$message.warning("请选择一条您要处理的记录~");
        return;
      }
      let para = this.currentSelectList[0];
      if (para.approvalStatus == "shen_pi_zhong:approval_status") {
        para.approvalStatus = "";
      }

      Api.memberRechargeDetail({ billTransferId: para.billTransferId }).then(
        (res) => {
          this.infosForm = res.data.result;
          this.infosForm.nickname = para.nickname;
          this.infosForm.pics = res.data.result.pics || [];
          let urls = res.data.result.pics || [];
          // console.log(urls);
          let fileArr = [];
          let imgArr = [];

          urls.forEach((item, index) => {
            let fileObj = {
              name: item.fileName,
              url: item.url,
            };
            fileArr.push(fileObj);
            let objs = {
              imgName: item.fileName,
              oldName: "",
            };
            imgArr.push(objs);
          });
          this.fileListImg = JSON.parse(JSON.stringify(fileArr));
          this.ImgNames = JSON.parse(JSON.stringify(imgArr));
          if (res.data.result.bankUserAccountId) {
            this.financeUserAccountList = [
              {
                userBankId: res.data.result.bankUserAccountId || "",
                accountName: res.data.result.bankUserAccountName || "",
                bankAccount: res.data.result.bankUserAccount || "",
              },
            ];
          }
          this.diaVisible_chuLi = true;
        }
      );

      // shen_pi_zhong:approval_status
      // this.diaVisible_chuLi = true;
      // this.infosForm = para;
      // this.infosForm.financeAssetaccountId = para.accountId;
    },
    //确定新增/编辑
    confirmBtn_addEdit() {
      let that = this;
      let picList = [];
      for (var i = 0; i < this.ImgNames.length; i++) {
        picList.push(this.ImgNames[i].imgName);
      }
      if (this.diaVistitle == "新建充值申请") {
        let params1 = {
          currency: this.addAndEditData.currency,
          transferAmount: this.addAndEditData.transferAmount,
          // dealAmount: this.addAndEditData.dealAmount,
          financeAssetaccountId: this.addAndEditData.financeAssetaccountId,
          memberId: this.addAndEditData.memberId,
          orderNumber: this.addAndEditData.orderNumber,
          pics: picList,
          transferTime: this.addAndEditData.transferTime,
          comment: this.addAndEditData.rechargComment || "",
          bankUserAccountId: this.addAndEditData.bankUserAccountId || "",
        };
        let sign = tools.getSign(params1);
        params1.sign = sign;
        Api.rechargeTransfer(params1).then((res) => {
          this.$message.success(res.data.message || "提交成功");
          setTimeout(() => {
            that.diaVisible_add = false;
            this.getData();
          }, 1000);
        });
      } else {
        let editData = {
          currency: this.addAndEditData.currency, //货币名称
          transferAmount: this.addAndEditData.transferAmount, //交易金额
          // dealAmount: this.addAndEditData.dealAmount, //交易金额
          orderNumber: this.addAndEditData.orderNumber, //订单号
          pics: picList,
          // transferBank: this.addAndEditData.transferBank || '', //转账银行
          transferTime: this.addAndEditData.transferTime, //转账时间
          billTransferId: this.addAndEditData.billTransferId, //转账申请单id
          userId: this.addAndEditData.transferorUserId, //会员id
          financeAssetaccountId: this.addAndEditData.financeAssetaccountId, //资金账户id
          comment: this.addAndEditData.rechargComment || "", //
          bankUserAccountId: this.addAndEditData.bankUserAccountId || "", //
        };
        let sign = tools.getSign(editData);
        editData.sign = sign;
        Api.updateMemberRecharge(editData).then((res) => {
          this.$message.success(res.data.message || "提交成功");
          setTimeout(() => {
            that.diaVisible_add = false;
            this.getData();
          }, 1000);
        });
      }
    },
    //处理
    confirmBtn_chuLi() {
      let that = this;
      if (!this.infosForm.approvalStatus) {
        this.$message.warning("请选择审批状态");
        return;
      }
      if (this.infosForm.approvalStatus == "ju_jue:approval_status") {
        if (!this.infosForm.rejectionReason) {
          this.$message.warning("拒绝原因不能为空");
          return;
        }
      }

      let param = {
        approvalStatus: this.infosForm.approvalStatus || "", //审批状态
        billTransferId: this.infosForm.billTransferId || "", //转账申请单id
        comment: this.infosForm.comment || "", //备注
        transferStatus: this.infosForm.transferStatus || "", //充值类型
        financeAssetaccountId: this.infosForm.financeAssetaccountId || "", //充值类型
        rejectionReason: this.infosForm.rejectionReason || "", //拒绝原因
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.handleMemberRecharge(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          setTimeout(() => {
            that.diaVisible_chuLi = false;
            that.getData();
          }, 1000);
        }
      });
    },

    //输入框绑定动态class
    more() {
      this.fetTableHeight();
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    //付款确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          ////console.log("error submit!!");
          return false;
        }
      });
    },
    uploadSectionImg(param) {
      // console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let imgName = res.data.result.fileName;
            let objs = {
              imgName: imgName,
              oldName: fileName,
            };
            this.ImgNames.push(objs);
          }
        })
        .catch((err) => {});
    },
    //删除图片
    handleRemove(file, fileListImg) {
      // console.log(file);
      // console.log(fileListImg);
      this.ImgNames.forEach((item, index) => {
        if (item.oldName == file.name || item.imgName == file.name) {
          this.ImgNames.splice(index, 1);
          return;
        }
      });
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //验货处理
    InspectionHandling() {
      this.$router.push("/BusinessManagement/Warehousing/InspectionHandling");
    },
    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      //////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "会员充值列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        ////console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      if (this.activeName == "second") {
        btnListHeight = this.$refs.btnList1.offsetHeight + 16;
        if (this.judge == true) {
          queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
        } else {
          queryHeigth = 50;
        }
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 30 + 10; //60==顶部高度,30==tabs高度,10==自定义
      return Math.floor(windonHeight - otherHeight) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let that = this;
      let arrObj = this.queryData;
      // let parm = this.queryData;
      // let arrObj = {};
      // for (let k in parm) {
      //   if (parm[k]) {
      //     arrObj[k] = parm[k];
      //   }
      // }

      arrObj.pageStart = this.pageNum;
      arrObj.pageTotal = this.size;

      Api.memberRechargeList(arrObj).then((res) => {
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          that.tableData = res.data.result.data || [];
          ////console.log(111, res.data.result.data || []);
          that.total = res.data.result.pageCount || 0;

          that.$nextTick(() => {
            that.$refs.mytable.doLayout();
          });
          this.fetTableHeight();
        }
      });
    },
    //充值记录
    getData2() {},
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    //重置
    queryDataClera(e) {
      this.queryData = {
        createEndTime: "", //申请结束时间
        createStartTime: "", //申请开始时间
        memberId: "", //会员号
        nickname: "", //会员名称
        storageId: "", //仓库id
        transferStatus: "", //充值类型
        transferNumber: "", //
        approvalStatus: "", //
      };
      if (e == "1") {
        this.queryData.approvalStatus = "shen_pi_zhong:approval_status";
      } else {
        this.queryData.approvalStatus = "";
      }
    },

    //顶部标签页切换
    handleClick() {
      // if (this.activeName == "second") {
      //   this.pageGroupName = "baoruorenglin1";
      //   this.SignInRecord();
      // } else {
      //   this.pageGroupName = "baoruorenglin";
      //   this.getGroupFindFieldSort();
      // }
    },
    //签收记录表头获取
    SignInRecord() {
      let that = this;
      //////console.log(this.pageGroupName)
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard1 = JSON.parse(arr[0].sortObject);
            this.sortName1 = arr[0].sortName;
          }
        } else {
          that.myTableHeard1 = that.tabelHeadeTitle1;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard1.length; i++) {
          if (that.myTableHeard1[i].isTotal) {
            totalArr.push(that.myTableHeard1[i].field);
          }
        }
        that.totalArr1 = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //设置模板按钮(签收记录)
    myModel1() {
      let that = this;
      that.show_sortTableHeard1 = true;
      that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
      if (selectLength == that.tabelHeadeTitle1.length) {
        that.allCheck1 = true;
      } else {
        that.allCheck1 = false;
      }
    },
    // 表格选择事件(待入库)
    handleSelectionChange(e) {
      this.currentSelectList = e;
    },
    // 表格选择事件(入库记录)
    handleSelectionChange1(e) {
      this.currentSelectList2 = e;
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
    // 点击某一项
    checktab1(idx, e) {
      let that = this;
      that.tabelHeadeTitle1[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck1 = true;
      } else {
        that.allCheck1 = false;
      }
    },
    //全选
    checkAll1(e) {
      let that = this;
      this.allCheck1 = e;
      for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
        this.tabelHeadeTitle1[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
    },
    // 合计
    getSummaries1(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr1.length; i++) {
          if (column.property == that.totalArr1[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // //分页
    // handleSizeChange2(val) {
    //   this.size2 = val;
    //   this.getData2();
    // },
    // handleCurrentChange2(val) {
    //   this.pageNum2 = val;
    //   this.getData2();
    // },
  },
};
</script>

<style scoped  lang="scss">
.el-table .warning-row {
  background: #f8f8f8;
}
.box {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}
.title {
  width: 100%;
  display: flex;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.ipustyle {
  width: 70%;
}
.frombox {
  width: 60%;
  margin: 30px;
  padding: 30px 80px;
}
.inpstyle {
  width: 100%;
}
.headerbut {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
}
.from1box {
  width: 80%;
  background-color: #f2f2f2;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px 50px;
  .formli {
    .inpbox {
      width: 140px;
    }
    .inpbox1 {
      width: 370px;
    }
  }
  .formli1 {
    .inpbox {
      width: 200px;
    }
  }
}
.inpbox {
  width: 100%;
}
.inpbox1 {
  width: 78%;
}
.butstyle {
  margin-left: 20px;
}
.headebut {
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.formbox {
  width: 80%;

  box-sizing: border-box;
  margin: 20px auto;
  .picture {
    width: 60%;
    margin: 20px auto;
    padding: 100px 0;
    border: 5px solid #f2f2f2;
  }
}
.tableBox {
  height: calc(100% - 260px);
  position: relative;
  width: 100%;
  margin: 0 auto;
  .el-table__common-options {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 40px;
    height: 28px;
    box-sizing: border-box;
    z-index: 5;
    .el-button {
      border: 0;
    }
    .righttagICon {
      width: 40px;
      height: 28px;
      background-color: #f0f0f0;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.container {
  background-color: #fff;
}
.DividingLine {
  background-color: darkblue;
  margin: 100%;
  height: 5px;
}
.box1 {
  background-color: #fff;
}

.pags[data-v-cb324df2] {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  //height: "";
}
.el-table__body tr.current-row > td {
  background-color: #ffeab6 !important;
  color: #606266;
}
.el-tabs__item:hover {
  color: #303133;
}

.PopupBut {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.pwdform {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  .scanning {
    width: 100%;
    display: flex;
    .determine {
      margin-top: 22px;
      margin-left: 10px;
    }
  }
  .singleinp {
    width: 80% !important;
    margin: 20px 0 !important;
  }
}

.newForm {
  // margin: 0 auto;
  // text-align: center;
  .queryItem1 {
    width: 100%;
    // margin-top: 10px;
    margin: 10px 20px 10px 0;
    // max-width: 300px;
  }
}

.formDialog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .queryItem1 {
    width: 45%;
  }
}
</style>
